<template>
    <div>
        {{get24hr}}
    </div>
</template>

<script>
import hrData from "@/api/24hr.json";
import {pool_list} from '@/constants/pool.js'
import axios from 'axios'
    export default {
        components: {},
        data() {
            return {
            }
        },
        computed: {
            get24hr() {
                return  hrData;
            },
        },
        beforeMount() {
            let length = pool_list.length;
            for (let i=0; i<length; i++) {
                var item = hrData[i];
                if (this.$store.state.liquidity.pairInfo[i].pair.priceChange.h24 != undefined)
                    item.priceChangePercent = (this.$store.state.liquidity.pairInfo[i].pair.priceChange.h24).toString();
                if (this.$store.state.liquidity.pairInfo[i].pair.volume.h24 != undefined)
                    item.volume = (this.$store.state.liquidity.pairInfo[i].pair.volume.h24).toString();

                item.lastPrice = this.$store.state.liquidity.pairInfo[i].pair.priceUsd;
            }
        },
        methods: {
        }
    }
</script>

<style>
</style>
